import React, { FC } from 'react';
import { ALIGNMENT, SKIN, Tabs } from 'wix-ui-tpa/Tabs';
import { classes } from './header-tabs-desktop.st.css';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { generateHeaderTabs } from './generate-header-tabs';
import { FeedType, HeaderTabsCommonProps } from './models';
import { RootState } from '../../reducers/root-state';
import { connect } from '../../../common/components/runtime-context';
import { flowRight } from 'lodash';
import { getCurrentMatch } from '../../../common/router/router-selectors';
// @ts-expect-error
import { ROUTE_HOME } from '@wix/communities-forum-client-commons/dist/src/constants/routes';

interface HeaderTabsDesktopProps {
  isHomePage: boolean;
  navigateToMainTab: (feedType: FeedType | null) => void;
}

const HeaderTabsDesktop: FC<HeaderTabsDesktopProps & HeaderTabsCommonProps> = ({
  t,
  mainPageType,
  activeFeedType,
  isCategoriesPageVisible,
  isHomePage,
  navigateToMainTab,
}) => {
  const items = generateHeaderTabs({
    t,
    mainPageType,
    isCategoriesPageVisible,
    activeFeedType,
  });

  const activeIndex = isHomePage ? items.findIndex(x => x.isActive) : -1;

  return (
    <Tabs
      className={classes.tabs}
      items={items.map(item => ({
        title: item.title,
        id: item.feedType as string,
      }))}
      activeTabIndex={activeIndex}
      onTabClick={index => {
        const activeFeedType = items[index].feedType;
        navigateToMainTab(activeFeedType);
      }}
      alignment={ALIGNMENT.left}
      skin={SKIN.clear}
    />
  );
};

const mapRuntimeToProps = (state: RootState, ownProps: any, actions: any) => ({
  navigateToMainTab: (feedType: FeedType | null) => actions.navigateToMainTab(feedType),
  isHomePage: getCurrentMatch(state).route === ROUTE_HOME,
});

export default flowRight(connect(mapRuntimeToProps), withTranslate)(HeaderTabsDesktop);
