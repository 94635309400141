import { FeedType, MainPageType } from './models';
import { TFunction } from '@wix/wix-i18n-config';

type HeaderTabItem = {
  id: FeedType;
  title: string;
  isVisible: boolean;
  isActive: boolean;
  feedType: FeedType | null;
};

type GenerateHeaderTabsDto = {
  t: TFunction;
  mainPageType: MainPageType;
  activeFeedType: string;
  isCategoriesPageVisible: boolean;
};

export const generateHeaderTabs = ({
  t,
  mainPageType,
  isCategoriesPageVisible,
  activeFeedType,
}: GenerateHeaderTabsDto): HeaderTabItem[] => {
  const isMainPageCategories = mainPageType === MainPageType.Categories;

  const categoriesItem: HeaderTabItem = {
    id: FeedType.Categories,
    title: t('tabs.categories'),
    isVisible: isCategoriesPageVisible,
    isActive: activeFeedType === FeedType.Categories,
    feedType: isMainPageCategories ? null : FeedType.Categories,
  };

  const postsItem: HeaderTabItem = {
    id: FeedType.Posts,
    title: t('tabs.posts'),
    isVisible: true,
    isActive: activeFeedType === FeedType.Posts,
    feedType: isMainPageCategories ? FeedType.Posts : null,
  };

  const myPostsItem: HeaderTabItem = {
    id: FeedType.MyPosts,
    title: t('tabs.my-posts'),
    isVisible: true,
    isActive: activeFeedType === FeedType.MyPosts,
    feedType: FeedType.MyPosts,
  };

  return [
    isMainPageCategories ? categoriesItem : postsItem,
    isMainPageCategories ? postsItem : categoriesItem,
    myPostsItem,
  ].filter(({ isVisible }) => isVisible);
};
