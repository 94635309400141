import PropTypes from 'prop-types';
import React, { FC } from 'react';
import BreadcrumbsWidget from '../breadcrumbs-widget';
import DesktopHeader from '../desktop-header';
import { renderSearchInput } from './render-search-input';
import HeaderTabs from '../header-tabs/header-tabs.component';
import { areBreadcrumbsVisible } from '../header-tabs';
import { connect } from '../../../common/components/runtime-context';
import { flowRight } from 'lodash';
import { RootState } from '../../reducers/root-state';
import classNames from 'classnames';
import styles from './internal-page-tabs-header.scss';
import Breadcrumbs from '../../components/breadcrumbs';

interface InternalPageTabsDesktopHeaderProps {
  params: any;
  routes: any;
  areBreadcrumbsVisible: boolean;
}

const InternalPageTabsDesktopHeader: FC<InternalPageTabsDesktopHeaderProps> = ({
  params,
  routes,
  areBreadcrumbsVisible,
  ...props
}) => {
  const breadcrumbsContainerClasses = classNames(styles.container, 'forum-card-border-color');

  return (
    <>
      <DesktopHeader
        className={styles.header}
        removeVerticalSpacing={true}
        left={<HeaderTabs />}
        right={<BreadcrumbsWidget searchInput={renderSearchInput()} params={params} />}
        {...props}
      />
      {areBreadcrumbsVisible && (
        <DesktopHeader
          className={breadcrumbsContainerClasses}
          size="medium"
          left={<Breadcrumbs routes={routes} params={params} />}
        />
      )}
    </>
  );
};

InternalPageTabsDesktopHeader.propTypes = {
  params: PropTypes.object.isRequired,
};

const mapRuntimeToProps = (state: RootState) => ({
  areBreadcrumbsVisible: areBreadcrumbsVisible(state),
});

export default flowRight(connect(mapRuntimeToProps))(InternalPageTabsDesktopHeader);
