import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styles from './desktop-header.scss';

const renderMenu = menu => {
  return menu && <div className={styles.menu}>{menu}</div>;
};

const DesktopHeader = ({ left, right, menu, size, className, removeVerticalSpacing }) => {
  const containerClassName = classNames(styles.container, 'header-background-color');

  const headerClassName = classNames([
    styles.header,
    size === 'medium' ? styles.mediumHeader : undefined,
    removeVerticalSpacing ? styles.headerNoSpacing : undefined,
  ]);

  return (
    <div className={classNames([containerClassName, className])} data-hook="desktop-header">
      <div className={classNames([headerClassName])}>
        {left}
        {right}
      </div>
      {renderMenu(menu)}
    </div>
  );
};

DesktopHeader.propTypes = {
  left: PropTypes.node,
  right: PropTypes.node,
  menu: PropTypes.node,
  size: PropTypes.string,
  className: PropTypes.string,
  removeVerticalSpacing: PropTypes.bool,
};

export default DesktopHeader;
