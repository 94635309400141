import { isArray } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import URI from '@wix/communities-forum-client-commons/dist/src/services/urijs';
import styles from './action-button.scss';
import withFontClassName from '../../hoc/with-font-class-name';

const SIZE_SMALL = 'sizeSmall';
const addQueryParams = (url, query) => new URI(url).addQuery(query).toString();

class ActionButton extends Component {
  render() {
    const {
      className,
      onClick,
      onMouseDown,
      children,
      dataHook,
      isButton,
      isLink,
      size,
      contentFontClassName,
      to,
      queryParams,
    } = this.props;
    const icon = isArray(children) ? children[0] : null;
    const text = isArray(children) ? children[1] : children;
    const rest = isArray(children) ? children.slice(2) : null;

    const elementClassnames = classNames(
      contentFontClassName,
      styles.actionItem,
      isButton ? '' : styles.listItem,
      className,
      'action-button',
      'forum-text-color',
      'forum-icon-fill',
      'button-hover-color',
    );

    const elementContent = (
      <div className={classNames(styles.actionWrapper, styles[size])} data-hook={dataHook}>
        {icon && <div className={styles.icon}>{icon}</div>}
        <div className={classNames(styles.actionText, 'action-button__text')}>{text}</div>
        {rest}
      </div>
    );

    if (isLink && to) {
      const url = queryParams ? addQueryParams(to, queryParams) : to;

      return (
        <a
          href={url}
          onClick={onClick}
          className={elementClassnames}
          data-hook="action-button"
          // @TODO maybe add "linkProps", instead of hardcoding only for external
          target="_blank"
          rel="noreferrer"
        >
          {elementContent}
        </a>
      );
    }

    return (
      <button
        onClick={onClick}
        onMouseDown={onMouseDown}
        className={elementClassnames}
        role={!isButton && 'menuitem'}
        data-hook="action-button"
      >
        {elementContent}
      </button>
    );
  }
}

ActionButton.propTypes = {
  contentFontClassName: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  children: PropTypes.node.isRequired,
  dataHook: PropTypes.string,
  isButton: PropTypes.bool,
  isLink: PropTypes.bool,
  size: PropTypes.string,
};

ActionButton.defaultProps = {
  onMouseDown: () => {},
};

ActionButton.SIZE_SMALL = SIZE_SMALL;

export default withFontClassName(ActionButton);
