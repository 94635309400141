import PropTypes from 'prop-types';
import { flowRight } from 'lodash';
import classNames from 'classnames';
import React from 'react';
import { DISCUSSION } from '@wix/communities-forum-client-commons/dist/src/constants/post-types';
import DropdownButton from '../dropdown-button';
import DiscussionPostButton from '../create-discussion-button';
import QuestionPostButton from '../create-question-button';
import { HorizontalSeparator, VerticalSeparator } from '../separator';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import withDeviceType from '../../hoc/with-device-type';
import styles from './create-post-button.scss';
import { connect } from '../../../common/components/runtime-context';
import withExperiment from '../../hoc/with-experiment';
import { EXPERIMENT_QUESTION_ON_TOP } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';

const CreatePostButton = ({
  categorySlug,
  postTypes,
  isDropdown,
  borderWidth,
  isMobile,
  userEventsClickCreatePostButton,
  isQuestionOnTop,
}) => {
  const renderNewPostDropdown = () =>
    isDropdown ? (
      <DropdownButton
        label="create-post-button.create-new-post"
        className={styles.dropdownButtons}
        dataHook="create-post-cta"
        onDropdownShow={userEventsClickCreatePostButton}
      >
        {!isQuestionOnTop && (
          <React.Fragment>
            <DiscussionPostButton categorySlug={categorySlug} isExtended />
            <HorizontalSeparator />
          </React.Fragment>
        )}
        <QuestionPostButton categorySlug={categorySlug} isExtended />
        {isQuestionOnTop && (
          <React.Fragment>
            <HorizontalSeparator />
            <DiscussionPostButton categorySlug={categorySlug} isExtended />
          </React.Fragment>
        )}
      </DropdownButton>
    ) : (
      <div
        className={classNames(
          styles.buttons,
          'forum-card-border-color',
          'forum-card-background-color',
        )}
        style={{ borderWidth }}
      >
        <div className={styles.createButton}>
          <DiscussionPostButton categorySlug={categorySlug} isExtended />
        </div>
        {isMobile ? (
          <HorizontalSeparator className={styles.horizontalSeparator} />
        ) : (
          <VerticalSeparator />
        )}
        <div className={styles.createButton}>
          <QuestionPostButton categorySlug={categorySlug} isExtended />
        </div>
      </div>
    );

  if (postTypes.length > 1) {
    return renderNewPostDropdown();
  }
  return (
    <div>
      <DiscussionPostButton categorySlug={categorySlug} />
    </div>
  );
};

CreatePostButton.propTypes = {
  categorySlug: PropTypes.string,
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
  isDropdown: PropTypes.bool,
  borderWidth: PropTypes.number.isRequired,
  isMobile: PropTypes.bool,
  userEventsClickCreatePostButton: PropTypes.func.isRequired,
};

CreatePostButton.defaultProps = {
  postTypes: [DISCUSSION],
  isDropdown: true,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  userEventsClickCreatePostButton: actions.userEventsClickCreatePostButton,
});

export default flowRight(
  withCardBorderWidth,
  withDeviceType,
  connect(mapRuntimeToProps),
  withExperiment({ isQuestionOnTop: EXPERIMENT_QUESTION_ON_TOP }),
)(CreatePostButton);
